import { useState } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { PreinitiateServices } from "../../../services/PreinitiateServices";
import DownloadIcon from "../../../assets/downloadIconGreen.svg";
import { CircularProgress } from "@mui/material";
const RejectionPopUp = ({ isOpen, selectedDoc }) => {
  const [status, setStatus] = useState("REJECTED");
  const [rejectionReason, setRejectionReason] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const handleRejectDocument = async () => {
    setLoading(true);
    const data = {
      status: status,
      rejectionReason: rejectionReason,
    };

    try {
      await PreinitiateServices.updateReferenceDocumentStatus(
        data,
        selectedDoc.id
      );
      window.alert("Document Rejected Successfully");
      isOpen(false);
    } catch (error) {
      window.alert(error);
    }
    setLoading(false);
  };
  return (
    <div className="fixed top-0 left-0 h-full w-full flex justify-center items-center bg-[#017C5750] z-30">
      <div className="relative bg-white rounded-[16px] w-[90%] h-[70%] xl:w-[50%] xl:h-[75%] p-4 xl:p-8">
        <div
          className="absolute top-4 right-4 xl:top-7 xl:right-8 cursor-pointer z-20"
          onClick={() => isOpen(false)}
        >
          <img src={CloseIcon} height={30} width={30} alt="close-icon" />
        </div>

        <div className=" h-[80%] w-full flex justify-center">
          {selectedDoc?.documentUrl?.slice(-3) === "pdf" ? (
            <iframe
              src={`http://docs.google.com/gview?url=${selectedDoc.documentUrl}&embedded=true`}
              fgrameborder="0"
              height="100%"
              width="100%"
            />
          ) : (
            <img
              src={selectedDoc.documentUrl}
              alt="doc-image"
              className="w-[100%] h-[100%] object-contain"
              // className="h-[70%]"
            />
          )}
          <a
            href={selectedDoc.documentUrl}
            className="absolute top-5 right-12 xl:top-8 xl:right-16  cursor-pointer"
            onClick={() => console.log("download button clicked")}
          >
            <img src={DownloadIcon} alt="download-icon" />
          </a>
        </div>
        <div className="flex justify-center xl:mt-8 ">
          <input
            type="text"
            placeholder="Rejection Reason"
            style={{ border: "1px solid #e4e4e4" }}
            className="h-[40px] font-semibold text-sm text-[#797979] rounded-[8px]
            pl-[12px] w-[100%] xl:w-[50%]"
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </div>
        <div className="flex justify-center mt-4 xl:mt-8">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div
              className="text-xs text-white bg-[#017C57] h-fit w-fit cursor-pointer rounded-full px-12 py-2"
              onClick={handleRejectDocument}
            >
              Reject
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectionPopUp;
